let root: any

const loadScript = async (url: string) => {
  return new Promise<void>((resolve, reject) => {
    console.log('loading', url)
    const script = document.createElement('script')
    script.src = url
    script.onload = () => {
      resolve()
    }
    script.onerror = () => {
      reject()
    }
    document.body.append(script)
  })
}

const render = async () => {
  if (!window.React) {
    await loadScript('https://cdn.redhare.cc/react@18.2.0/umd/react.development.js')
    await loadScript('https://cdn.redhare.cc/react-dom@18.2.0/umd/react-dom.development.js')
  }

  const React = window.React as any
  const ReactDOM = window.ReactDOM as any
  const App = await import('./App').then(res => res.default);

  root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(
    React.createElement(App)
  );
}

const unmountApp = () => {
  root?.unmount()
  root = undefined
}

// standalone模式下运行
declare global {
  interface Window {
    __POWERED_BY_QIANKUN__: any
  }
}

if (!window.__POWERED_BY_QIANKUN__) {
  console.log('render in standalone mode')
  render();
}

export async function bootstrap() {
  console.log('[qiankun] bootstrap');
}

export async function mount(props) {
  console.log('[qiankun] mount, props:', props);
  render();
}

export async function unmount() {
  console.log('[qiankun] unmount');
  unmountApp()
}